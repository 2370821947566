

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#F4F6F6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Poppins', sans-serif;
}

html {
  overflow: scroll;
  overflow-x: hidden;
} 
 ::-webkit-scrollbar {
  width: 0;  
  background: transparent; 
}

::-webkit-scrollbar-thumb {
  background: white;


}

/* @media print {
  .page-break {
    page-break-before: always;
  }

  .no-break {
    page-break-inside: avoid;
  }
} */


.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  font-weight: bold;
  font-size: 90%;
  min-height: 0;
  padding: 20px;
  display: flex; 
  align-items: center; 
}


.rbc-header:nth-child(1) {
  border: 1px solid #ddd;
  padding: 15px;
  display:"flex";
  justify-content:"center";
  align-items: center;

}


.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
  padding: 15px;
  display:"flex";
  justify-content:"center";
  align-items: center;
  text-align: center;
}


.rbc-off-range-bg {
  background: white !important;
}

.recharts-bar-rectangle:hover {
  filter: none; 
}